import Tools from './tools';

export const setUserToken = (val: any)=> {
  setStorage('app_token', val);
}

export const getUserToken = ()=> {
  return getStorageParam('app_token');
}


export const getStorageParam = (str: string)=> {
  const urlParam = Tools.param2Obj(window.location.href);
  if (urlParam.hasOwnProperty(str)) {
    return urlParam[str];
  }
  return getStorage(str);
}

export const setStorage =(key: string, val: any)=> {
  localStorage.setItem(key, val)
}

export const getStorage =(key: string): any => {
  return localStorage.getItem(key);
}

export const clearStorage =()=> {
  localStorage.clear();
}

export const removeStorage =(key: string)=> {
  localStorage.removeItem(key);
}

