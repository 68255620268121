import {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import PayUtils from "./pay_utils";

export interface WxPayState {
  appId: string;
  nonceStr: string;
  paySign: string;
  prepay_id: string;
  timeStamp: string;
  signType: string;
  ordersn: string;
  paysn: string;
  [key: string]: any;
}

/**
 * 计时器 hooks
 * @param callback
 * @param delay
 */
const useInterval = (callback: any, delay: number) => {
  const savedCallback: any = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

/**
 * 微信支付 hooks
 */
const useWxPay = ()=> {
  const navigate = useNavigate();
  return {
    pay: (data: WxPayState)=> {
      PayUtils.wxPay(data).then(res => {
        if (res) navigate(res.url+'&paysn='+data.paysn);
      })
    }
  }
}

/***
 * loading url
 */

const useRequest = ()=> {
  const [status, setStatus] = useState(false);
  const [error, setError] = useState<any>();

  const run = useCallback(async (...funs) => {
    setStatus(true);
    try {
      await Promise.all(funs.map(fn => {
        if (typeof fn === 'function') {
          return fn();
        }
        return fn;
      }))
    } catch (e) {
      setError(e)
    } finally {
      setStatus(false)
    }
  }, [])

  return {run, status, error}
}
export { useInterval, useWxPay, useRequest };
