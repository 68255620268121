import HttpServer from "@utils/http";

interface RechargeInfo {
  app_id: string
  paysn: string
  source: 'wx'|'ali'|'h5'
  wx_token?: string
  ali_token?: string
}

// 获取收款码信息
export const getShopInfo = (qrcode_id: string) => {
  return HttpServer.get({
    url: "hyk/user.merchant_shop_qrcode_order/get_shop_info",
    query: {
      qrcode_id,
    },
  });
};

// 支付订单
export const payOrder = (orderid: string, source: string, payment_code: string, open_id: string) => {
  return HttpServer.get({
    url: "hyk/user.merchant_shop_qrcode_order/pay_order",
    query: {
      id: orderid,
      payment_code,
      source,
      open_id
    },
  });
};

// 支付验证订单
export const checkOrderPay = (orderid: string) => {
  return HttpServer.post({
    url: "hyk/user.merchant_shop_qrcode_order/pay_order_check",
    params: {
      id: orderid,
    },
  });
};

// 提交订单 
export const submitOrder = (qrcode_id: string, amount: string, remark: string, coupon_data_ids?: string, random_discount_id?: number, random_dec_discount_money?: number) => {
  return HttpServer.post({
    url: "hyk/user.merchant_shop_qrcode_order/submit_order",
    params: {
      qrcode_id,
      money: amount,
      remark,
      coupon_data_ids,
      random_discount_id,
      random_dec_discount_money
    },
  });
};

// 充值提交订单
export const createPayment = (params: RechargeInfo) => {
  return HttpServer.post({
    url: "client/recharge/create_payment",
    params
  });
};

// 加油站 ali静默登录
export const aliUserLogin = (app_id:string, auth_code:string, ext_appid: string) => {
  return HttpServer.post({
    url: "client/ali.login/login",
    params: {
      app_id,
      auth_code,
      ext_appid
    }
  });
};

// 获取领取的优惠券
export const getMyCouPonList = (shop_id:string, money:string) => {
  return HttpServer.get({
    url: "hyk/user.coupon/get_coupon_list",
    query: {
      shop_id,
      money
    }
  });
};

// 计算收款码
export const computeMoney = (qrcode_id:string, money:string, coupon_data_ids?: string) => {
  return HttpServer.post({
    url: "hyk/user.merchant_shop_qrcode_order/compute",
    params: {
      qrcode_id,
      money,
      coupon_data_ids
    }
  });
};

// hyk 阿里登录
export const hykAliLogin = (auth_code:string) => {
  return HttpServer.post({
    url: "hyk/user.merchant_shop_qrcode_order/ali_login",
    params: {
      ext_appid: 2021003117624652,
      auth_code,
    }
  });
};
