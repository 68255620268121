import HttpServer from "@utils/http";

interface ItemOrder {
  app_id: string;
  wx_token?: string;
  ali_token?: string;
  qrcode_id?: string;
  room_id?: string;
  emp_id?: string;
  money: string;
  source: 'wx'|'ali'|'h5'
}

interface StoreInfo {
  app_id: string
  qrcode_id?: string
  room_id?: string
  emp_id?: string
}

// 用户注册
export const wxOpenCardUrl = (app_id: string, callback: string) => {
  return HttpServer.post({
    url: "client/wechat.login/wx_card_url",
    params: {
      app_id,
      callback,
    },
  });
};

// 创建订单
export const createOilOrder = (params: ItemOrder) => {
  return HttpServer.post({
    url: "client/normal/create_order",
    params
  });
};

export const getButtonByNameStatus = (app_id: string, button_name: string) => {
  return HttpServer.post({
    url: "api/tool/get_button",
    params: {
      app_id,
      button_name
    }
  });
};

// 获取门店信息
export const getMyStoreInfo = (params: StoreInfo) => {
  return HttpServer.post({
    url: "client/normal/room_info",
    params
  });
};

// 取消订单
export const failOrderCallBack = async (ordersn: string) => {
  return await HttpServer.post({url: 'client/pay_fail/order', params: {
    ordersn
  }})
}

// 充值订单详情
export const getRechargeInfo = (app_id:string, paysn: string) => {
  return HttpServer.post({
    url: "client/recharge/pay_info",
    params: {
      app_id,
      paysn
    }
  });
};