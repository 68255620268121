import Tools from './tools';

export const setUserToken = (val: any)=> {
  setSessionStorage('app_token', val);
}

export const getSesUserToken = ()=> {
  return getSesStorageParam('app_token');
}

export const getSesStorageParam = (str: string)=> {
  const urlParam = Tools.param2Obj(window.location.href);
  if (urlParam.hasOwnProperty(str)) {
    return urlParam[str];
  }
  return getSessionStorage(str);
}

export const setSessionStorage =(key: string, val: any)=> {
  sessionStorage.setItem(key, val)
}

export const getSessionStorage =(key: string): any => {
  return sessionStorage.getItem(key);
}

export const clearSessionStorage =()=> {
  sessionStorage.clear();
}

export const removeSessionStorage =(key: string)=> {
  sessionStorage.removeItem(key);
}

